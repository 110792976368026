import {
  actions as AnalyticsActions,
  categories as AnalyticsCategories,
  labels as AnalyticsLabels
} from "analytics/constants";
import { isFailure, isSuccess } from "common/api-status/utils";
import {
  getPrivacyProtectionCost,
  getPrivacyProtectionTenure,
  parseStringAsBoolean
} from "common/util";

export const actionTypes = {
  DISABLE_PRIVACY_PROTECTION: "[Domain] Disable privacy protection",
  DISABLE_FREE_PRIVACY_PROTECTION: "[Domain] Disable free privacy protection",

  ENABLE_PRIVACY_PROTECTION: "[Domain] Enable privacy protection",
  ENABLE_FREE_PRIVACY_PROTECTION: "[Domain] Enable free privacy protection",

  PURCHASE_PRIVACY_PROTECTION: "[Domain] Purchase privacy protection",

  SHOW_PRIVACY_ENABLE_MODAL: "[Domain] Show privacy enable modal",
  SHOW_BUY_PRIVACY_MODAL: "[Domain] Show buy privacy modal",
  SHOW_BUY_DOMAIN_PROTECT_MODAL: "[Domain] Show buy domain protect modal",
  SHOW_PRIVACY_DISABLE_MODAL: "[Domain] Show disable privacy modal",
  SHOW_FREE_PRIVACY_ENABLE_MODAL: "[Domain] Show enable free privacy modal",
  SHOW_FREE_PRIVACY_DISABLE_MODAL: "[Domain] Show disable free privacy modal"
};

export const initialState = {
  privacy: {
    hasPaidPrivacy: false,
    usesPaidPrivacy: false,
    isPrivacyProtectionAllowed: true,
    checked: false,
    hasFreePrivacy: false,
    usesFreePrivacy: false,
    cost: 0,
    duration: 0
  }
};

export const actions = {
  showModal: (state, shouldEnable, meta) => {
    const [enableAction, disableAction] = helpers.getActions(state);
    return {
      type: shouldEnable ? enableAction : disableAction,
      ...meta
    };
  },
  showBuyPrivacyModal: props => ({
    type: actionTypes.SHOW_BUY_PRIVACY_MODAL,
    ...props.meta
  }),

  enable: payload => ({
    type: actionTypes.ENABLE_PRIVACY_PROTECTION,
    payload,
    track: {
      eventCategory: AnalyticsCategories.domains_view,
      eventAction: AnalyticsActions.privacy_protection_on,
      eventLabel: AnalyticsLabels.privacy_protection
    }
  }),
  enableSuccess: payload => ({
    type: isSuccess(actionTypes.ENABLE_PRIVACY_PROTECTION),
    ...payload
  }),
  enableFailure: (error, payload) => ({
    type: isFailure(actionTypes.ENABLE_PRIVACY_PROTECTION),
    error
  }),
  enableFreePrivacy: payload => ({
    type: actionTypes.ENABLE_FREE_PRIVACY_PROTECTION,
    payload,
    track: {
      eventCategory: AnalyticsCategories.domains_view,
      eventAction: AnalyticsActions.privacy_protection_on,
      eventLabel: AnalyticsLabels.privacy_protection
    }
  }),
  enableFreePrivacySuccess: payload => ({
    type: isSuccess(actionTypes.ENABLE_FREE_PRIVACY_PROTECTION),
    ...payload
  }),
  enableFreePrivacyFailure: (error, payload) => ({
    type: isFailure(actionTypes.ENABLE_FREE_PRIVACY_PROTECTION),
    error
  }),
  disable: payload => ({
    type: actionTypes.DISABLE_PRIVACY_PROTECTION,
    payload,
    track: {
      eventCategory: AnalyticsCategories.domains_view,
      eventAction: AnalyticsActions.privacy_protection_off,
      eventLabel: AnalyticsLabels.privacy_protection
    }
  }),
  disableSuccess: payload => ({
    type: isSuccess(actionTypes.DISABLE_PRIVACY_PROTECTION),
    ...payload
  }),
  disableFailure: (error, payload) => ({
    type: isFailure(actionTypes.DISABLE_PRIVACY_PROTECTION),
    error,
    payload
  }),
  disableFreePrivacy: payload => ({
    type: actionTypes.DISABLE_FREE_PRIVACY_PROTECTION,
    payload //TODO: maybe add analytics
  }),
  disableFreePrivacySuccess: payload => ({
    type: isSuccess(actionTypes.DISABLE_FREE_PRIVACY_PROTECTION),
    ...payload
  }),
  disableFreePrivacyFailure: payload => ({
    type: isFailure(actionTypes.DISABLE_PRIVACY_PROTECTION),
    ...payload
  })
};

export const parsers = {
  detailsParser: (
    {
      privacyprotectedallowed = "false",
      gdpr = {
        eligible: "false",
        enabled: "false"
      },
      isprivacyprotected = "false",
      privacyprotectendtime = "",
      endtime = "",
      privacyonly = "false",
      domainprotectplusendtime = ""
    },
    meta
  ) => ({
    checked:
      parseStringAsBoolean(isprivacyprotected) ||
      (parseStringAsBoolean(gdpr.eligible) &&
        parseStringAsBoolean(gdpr.enabled)),
    hasPaidPrivacy: !!privacyprotectendtime,
    usesPaidPrivacy: parseStringAsBoolean(isprivacyprotected),
    hasFreePrivacy: parseStringAsBoolean(gdpr.eligible),
    usesFreePrivacy: parseStringAsBoolean(gdpr.enabled),
    isPrivacyProtectionAllowed: parseStringAsBoolean(privacyprotectedallowed),
    privacyOnly: parseStringAsBoolean(privacyonly),
    endtime,
    domainprotectplusendtime: domainprotectplusendtime
  }),
  pricingParser: (payload, { expiryTimestamp, privacyprotectendtime }) => {
    let { privacy_protection: privacyProtectionCost } =
      payload.reseller_pricing ?? payload;
    return {
      cost: getPrivacyProtectionCost(
        privacyProtectionCost,
        getPrivacyProtectionTenure(expiryTimestamp)
      ),
      duration: getPrivacyProtectionTenure(
        expiryTimestamp,
        privacyprotectendtime
      )
    };
  }
};

export const helpers = {
  getActions: state => {
    if (state.hasFreePrivacy) {
      if (state.usesFreePrivacy) {
        return [
          actionTypes.SHOW_PRIVACY_ENABLE_MODAL,
          actionTypes.SHOW_FREE_PRIVACY_DISABLE_MODAL
        ];
      } else {
        return [
          actionTypes.SHOW_FREE_PRIVACY_ENABLE_MODAL,
          actionTypes.SHOW_PRIVACY_DISABLE_MODAL
        ];
      }
    } else {
      return [
        actionTypes.SHOW_PRIVACY_ENABLE_MODAL,
        actionTypes.SHOW_PRIVACY_DISABLE_MODAL
      ];
    }
  }
};
