import classNames from "classnames";
import { BrandConfigurationOperations } from "common/brand-configuration";
import UpdateButton from "common/buttons/update";
import { formatAmountWithCurrency } from "common/localize-amount";
import WPModal from "common/modal";
import ModalHandler from "common/modal-handler";
import {
  actionTypes as payActionTypes,
  actions as payActions
} from "common/pay-buttons/ducks";
import { colors, fonts } from "common/styles";
import WPTooltip from "common/tooltip";
import { applyAll } from "common/util";
import { css } from "linaria";
import { styled } from "linaria/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { actionTypes as upsellActionTypes } from "./ducks";
import { CloseModal } from "../../assets/close-modal";
import { Profile, Sitelock as NoSecurity } from "assets";

const PPDummyData = () => [
  {
    isProtected: false,
    title: "Public Profile",
    icon: Profile,
    fields: [
      "YOUR First and Last Name ",
      "YOUR Address(including City, State and Postal Code)",
      "YOUR Phone Number",
      "YOUR Email Address"
    ]
  },
  {
    isProtected: true,
    title: "No Domain Monitoring & Security",
    icon: NoSecurity,
    fields: [
      "No blacklist monitoring for your site's reputation",
      "No scanning for malware",
      "No security alerts to monitor unauthorized changes"
    ]
  }
];

let PPModalFor = styled.div`
  font: 400 12px/1.5 ${fonts.OpenSans};
  color: ${colors.gray.gray900};
`;

const Tooltip = styled.span`
  display: flex;
  flex-flow: column;
`;

const TooltipTitle = styled.span`
  border-bottom: 1px dashed ${colors.gray.gray900};
`;

let TooltipText = styled.span`
  color: #ffffff;
  font: 400 12px/1.5 ${fonts.OpenSans};
`;

let PPModalContentText = styled.p`
  margin: 20px 0px 5px;
  position: relative;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.black.regular};
  font-family: ${fonts.OpenSans};
`;

let PPModalContentSubtext = styled.p`
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  font-family: ${fonts.OpenSans};
  color: ${colors.black.regular};
  margin-bottom: 5px;
`;

const PPModalTitle = styled.div`
  font: 450 15px/1.2 ${fonts.BrandonGrotesque};
  letter-spacing: 0.5px;
  margin: 0 auto;
  text-align: center;
  color: ${colors.black.regular};
`;

let PPModalHeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

let PPDetailsWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  gap: 30px;
`;

let PPDetailsBox = styled.div`
  border: 1px solid ${colors.gray.gray400};
`;

const PPDetailsBoxTitle = styled.h4`
  padding: 15px 25px;
  line-height: 21px;
  font: 600 14px ${fonts.OpenSans};
  margin: 0;
`;

const PPValue = styled.p`
  color: ${colors.black.regular};
  font: 600 13px ${fonts.OpenSans};
  display: flex;
  gap: 10px;
  p {
    width: 90%;
  }
  svg g {
    fill: rgb(231, 96, 96);
    circle {
      stroke: rgb(231, 96, 96);
    }
    path {
      fill: white;
    }
  }
`;

const UpsellModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: Dispatch = useDispatch();
  const { brandConfiguration } = useSelector(state => ({
    brandConfiguration: BrandConfigurationOperations.findById(
      state.root.userInfo.parentid
    )
  }));

  let whoisUrl = brandConfiguration?.urls.storefront.whoisUrl ?? "";
  let sampleEmail = brandConfiguration?.urls.storefront.sampleEmail ?? "";
  if (process.env.FEATURE_ENDPOINT === "customer") {
    whoisUrl = "https://publicdomainregistry.com/whois";
    sampleEmail = "yourdomain@domain.com";
  }

  return (
    <ModalHandler
      opensOn={upsellActionTypes.OPEN_PRIVACY_PROTECTION_UPSELL_MODAL}
      closesOn={payActionTypes.CHOOSE_PAYMENT}
      render={(
        modalProps,
        {
          title,
          payButtonsInfo,
          totalCost,
          assignedDomain,
          currencySymbol,
          goBackToPrevState,
          domOnlyOrdersWithoutPP,
          totalWithPP,
          domOnlyOrdersWithoutPPCost
        }
      ) => {
        const domainNameforOrdersWithoutPP = [];
        const ppOrdersCount = domOnlyOrdersWithoutPP?.length;
        const haveBothPPAllowedAndNotAllowedDOMOrders =
          domOnlyOrdersWithoutPP?.some(
            _ => _?.meta?.params?.isPrivacyProtectionAllowed
          ) &&
          domOnlyOrdersWithoutPP?.some(
            _ => _?.meta?.params?.isPrivacyProtectionAllowed == false
          );
        if (ppOrdersCount > 1) {
          assignedDomain = `${ppOrdersCount} Orders `;
        } else {
          assignedDomain =
            !!domOnlyOrdersWithoutPP &&
            domOnlyOrdersWithoutPP[0]?.meta?.domainname;
        }

        const selectedTenure =
          !!domOnlyOrdersWithoutPP && domOnlyOrdersWithoutPP[0]?.params.years;
        const sellingPrice = formatAmountWithCurrency({
          cost: domOnlyOrdersWithoutPPCost,
          currency: currencySymbol,
          currencyDisplay: "code"
        });
        const updatedOrdersWithPPAddon = payButtonsInfo?.actionDetails?.value?.map(
          order => {
            let isMatch = domOnlyOrdersWithoutPP.some(
              ({ key }) => key === order?.key
            );
            if (isMatch) {
              const { key, params, pathParams, ...rest } = order;
              domainNameforOrdersWithoutPP.push(
                <TooltipText key={key}>{rest?.meta?.domainname}</TooltipText>
              );
              return {
                key,
                params: { ...params, "purchase-privacy": true },
                ...rest
              };
            } else {
              return order;
            }
          }
        );

        const commonClickProps = {
          isPendingPayment: payButtonsInfo?.isPendingPayment,
          transid: payButtonsInfo?.transid,
          layoutInfo: payButtonsInfo?.layoutInfo,
          startAndEndActionTypes: payButtonsInfo?.startAndEndActionTypes,
          title: t("CONFIRM_RENEWAL", "CONFIRM RENEWAL"),
          assignedDomain,
          goBackToPrevState: () => dispatch(modalProps.triggerAction)
        };

        let isPrivacyProtectionAllowed = payButtonsInfo?.actionDetails?.value?.some(
          _ => _?.meta?.params?.isPrivacyProtectionAllowed
        );

        return (
          <WPModal
            show={modalProps.show}
            onHide={modalProps.onHide}
            alignCenter
            width={720}
            title=""
            mainContent={
              <>
                <PPModalHeaderWrapper>
                  {goBackToPrevState && (
                    <button
                      style={{ fontSize: "12px", padding: "0px" }}
                      className="wp-btn-anti"
                      onClick={applyAll(modalProps.onHide, goBackToPrevState)}
                      type="button"
                    >
                      Back
                    </button>
                  )}
                  {assignedDomain && (
                    <PPModalTitle>
                      {title}
                      <PPModalFor style={{ marginBottom: "3px" }}>
                        Protect your personal information, website, and domain
                        name.
                      </PPModalFor>
                    </PPModalTitle>
                  )}
                </PPModalHeaderWrapper>
                <PPModalContentText style={{ margin: "22px 0px 6px" }}>
                  Without Domain Protect+
                </PPModalContentText>
                <PPModalContentSubtext>
                  Your domain name and website will not be scanned for malware
                  or blacklist monitoring
                </PPModalContentSubtext>
                <PPDetailsWrapper>
                  {PPDummyData().map(
                    ({ title, icon, fields, isProtected }, index) => (
                      <PPDetailsBox
                        key={index}
                        style={
                          isPrivacyProtectionAllowed
                            ? { display: "inline-block", width: "50%" }
                            : isProtected
                            ? { display: "inline-block", width: "100%" }
                            : { display: "none", width: "0%" }
                        }
                      >
                        <PPDetailsBoxTitle
                          style={{
                            background: "#FFF8F8",
                            color: colors.orange.dull,
                            display: "flex"
                          }}
                        >
                          <img
                            alt="profile-logo"
                            className="profile-logo"
                            src={icon}
                            style={{
                              marginRight: "10px",
                              width: "24px",
                              height: "24px"
                            }}
                          />
                          {title}
                        </PPDetailsBoxTitle>
                        <div style={{ padding: "15px 25px" }}>
                          {fields.map(text => (
                            <div key={text}>
                              <PPValue
                                style={{
                                  color: !isProtected
                                    ? colors.orange.dull
                                    : colors.black.regular,
                                  fontSize: "14px",
                                  lineHeight: "21px",
                                  fontWeight: 400
                                }}
                              >
                                {isProtected && (
                                  <CloseModal height="24" width="24" />
                                )}
                                <p>{text}</p>
                              </PPValue>
                            </div>
                          ))}
                        </div>
                      </PPDetailsBox>
                    )
                  )}
                </PPDetailsWrapper>
                {(!isPrivacyProtectionAllowed ||
                  haveBothPPAllowedAndNotAllowedDOMOrders) && (
                  <PPModalContentText
                    style={{
                      margin: "12px 0 4px",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#666666",
                      fontWeight: 400
                    }}
                  >
                    Note: Privacy Protection is not supported for{" "}
                    <a
                      href="https://livepreview.myorderbox.com/kb/servlet/KBServlet/faq1205.html"
                      target="_blank"
                    >
                      certain TLDs
                    </a>
                  </PPModalContentText>
                )}
                <PPModalContentText
                  style={{
                    margin: "12px 0 20px",
                    fontSize: "11px",
                    lineHeight: "18px",
                    color: "#697489",
                    fontWeight: 400
                  }}
                >
                  If the GDPR applies to you, we will not display your
                  Registration Information on the public WHOIS Site
                </PPModalContentText>
                <PPModalContentText
                  style={{
                    margin: "20px 0 10px",
                    fontSize: "13px",
                    lineHeight: "18px",
                    color: "#596275",
                    fontWeight: 400
                  }}
                >
                  Domain Protect+ for{" "}
                  {ppOrdersCount > 1 ? (
                    <WPTooltip
                      position="top"
                      label={<Tooltip>{domainNameforOrdersWithoutPP}</Tooltip>}
                    >
                      {<TooltipTitle>{assignedDomain}</TooltipTitle>}
                    </WPTooltip>
                  ) : (
                    assignedDomain
                  )}{" "}
                  {ppOrdersCount === 1 &&
                    `(${selectedTenure} ${
                      selectedTenure > 1 ? "years" : "year"
                    }) `}
                  - {sellingPrice}
                </PPModalContentText>

                <div style={{ textAlign: "center" }}>
                  <UpdateButton
                    data-testid="proceed-to-upsell-modal"
                    className={classNames(
                      "wp-btn-green-with-icon pay-card-icon",
                      css`
                        padding: 16px 30px 16px 62px !important;
                        margin: 0px auto;
                        width: unset !important;
                        display: block !important;
                        &::before {
                          left: 35px !important;
                        }
                      `
                    )}
                    onClick={() =>
                      dispatch(
                        payActions.choosePayment({
                          actionDetails: {
                            cost: totalWithPP,
                            currencySymbol,
                            ...payButtonsInfo?.actionDetails,
                            value: updatedOrdersWithPPAddon
                          },
                          ...commonClickProps
                        })
                      )
                    }
                    type="button"
                  >
                    {t("PROCEED_WITH_PP", "PROCEED WITH DOMAIN PROTECT+")}
                  </UpdateButton>
                  <button
                    style={{
                      fontSize: "13px",
                      padding: "11px",
                      lineHeight: "19px",
                      fontWeight: 400
                    }}
                    className="wp-btn-anti"
                    onClick={() =>
                      dispatch(
                        payActions.choosePayment({
                          actionDetails: {
                            cost: totalCost,
                            currencySymbol,
                            ...payButtonsInfo?.actionDetails
                          },
                          ...commonClickProps
                        })
                      )
                    }
                    type="button"
                  >
                    Continue
                  </button>
                </div>
              </>
            }
          />
        );
      }}
    />
  );
};

export default UpsellModal;
